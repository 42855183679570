import { BrowserRouter, Route, Routes } from "react-router-dom";
import FormularioLogin from "./pages/Cue";
import LayautPublico from "../src/components/LayautPublico";
import Autorizaciones from "./pages/Autorizaciones";
import CrearAutorixacion from "./pages/crearAutorizacion";
import ObtenerTokenFonasa from "./hooks/GetToken";
import Logout from "./pages/Logout";
import LayoutPrivado from "./components/LayoutPrivado";
import Code from "./pages/codigo";
export default function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayautPublico />} >
          <Route path="/" element={<FormularioLogin />} />
          <Route path="/cue/callback" element={<ObtenerTokenFonasa />} />
          <Route path="/cue/logout" element={<Logout />} />
          <Route path="/cue/code" element={<Code />} />
        </Route>
        <Route element={<LayoutPrivado />} >
          <Route path="/autorizaciones/auth" element={<Autorizaciones />} />
          <Route path="/autorizaciones/crear" element={<CrearAutorixacion />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}