import { useState } from 'react';

export default function useGetInfoPersona() {

    const [userInitial, setUserInitial] = useState('');
    const [dataBeneficiario, setDataBeneficiario] = useState([]);


    const getInfo = (sub) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "rut": sub,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_INFO_PERSONA, requestOptions)
            .then(response => response.json())
            .then(result => {
                const firstName = result.InfoBeneficiario.nombres.split(' ')[0];
                setUserInitial(firstName.charAt(0).toUpperCase());
                setDataBeneficiario(result.InfoBeneficiario);
            })
            .catch(error => console.log('error', error));
    }

    return {
        getInfo, userInitial, dataBeneficiario
    }
}