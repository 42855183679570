import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import LinearProgress from '@mui/material/LinearProgress';

const MediaCard = () => {
    const [run, setRun] = useState('');
    const [aplicativo, setAplicativo] = useState('');
    const [modulo, setModulo] = useState('');
    const [folio, setFolio] = useState('');
    const [nombre , setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [rut_prestador, setRutPrestador] = useState('');
    const [nombre_prestador, setNombrePrestador] = useState('');
    const [sucursal, setSucursal] = useState('');
    const [monto, setMonto] = useState('');
    const [segundo_fac, setSegundoFac] = useState('');
    const [resultado, setResultado] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const crearAutorizacion = () => {

        if (!run || !aplicativo || !modulo || !folio) {
            Swal.fire({
                icon: 'error',
                title: 'Campos requeridos',
                text: 'Por favor, complete todos los campos.',
            });
            return;
        }

        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_AUTORIZACIONES + `insertarData?run=${run}&aplicativo=${aplicativo}&modulo=${modulo}&folio=${folio}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setResultado(result);
                setIsLoaded(true);
            })
            .catch(error => console.log('error', error));
    };

    const obtenerAutorizacion = () => {
        if (!resultado) return;

        fetch(process.env.REACT_APP_URL_AUTORIZACIONES + `obtenerAutorizacionHistoricoId?id_transaccion=${resultado.id_transaccion}&aplicativo=${aplicativo}&run=${run}`)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setIsLoaded(true);

                if (result.estado === '1') {
                    mostrarMensaje('success', `La autorización ${aplicativo} ha sido aprobada`);
                    setIsLoaded(false);
                } else if (result.estado === '2') {
                    setIsLoaded(false);
                    mostrarMensaje('error', `La autorización ${aplicativo} ha sido rechazada`);
                }
            })
            .catch(error => console.log('error', error));
    };

    const mostrarMensaje = (icon, text) => {
        Swal.fire({
            icon: icon,
            title: icon === 'success' ? 'Autorización Aprobada' : 'Autorización Rechazada',
            text: text,
        });
    };

    useEffect(() => {
        if (isLoaded) {
            const interval = setInterval(obtenerAutorizacion, 5000);
            return () => clearInterval(interval);
        }
    }, [isLoaded]);

    return (
        <>
            <Card sx={{ maxWidth: 345, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 'auto', marginTop: '10%' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Formulario
                    </Typography>
                    {isLoaded ? (
                        <>
                            <LinearProgress />
                        </>
                    ) : (
                        <></>
                    )}
                    <form onSubmit={(e) => { e.preventDefault(); crearAutorizacion(); }}>
                        <TextField id="outlined-basic" label="Run" sx={{ m: 1 }} variant="outlined" value={run} onChange={e => setRun(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Aplicativo" sx={{ m: 1 }} variant="outlined" value={aplicativo} onChange={e => setAplicativo(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Modulo" sx={{ m: 1 }} variant="outlined" value={modulo} onChange={e => setModulo(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Folio" sx={{ m: 1 }} variant="outlined" value={folio} onChange={e => setFolio(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Nombre" sx={{ m: 1 }} variant="outlined" value={nombre} onChange={e => setNombre(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Correo" sx={{ m: 1 }} variant="outlined" value={correo} onChange={e => setCorreo(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Telefono" sx={{ m: 1 }} variant="outlined" value={telefono} onChange={e => setTelefono(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Rut Prestador" sx={{ m: 1 }} variant="outlined" value={rut_prestador} onChange={e => setRutPrestador(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Nombre Prestador" sx={{ m: 1 }} variant="outlined" value={nombre_prestador} onChange={e => setNombrePrestador(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Sucursal" sx={{ m: 1 }} variant="outlined" value={sucursal} onChange={e => setSucursal(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Monto" sx={{ m: 1 }} variant="outlined" value={monto} onChange={e => setMonto(e.target.value)} disabled={isLoaded} />
                        <br />
                        <TextField id="outlined-basic" label="Segundo Fac" sx={{ m: 1 }} variant="outlined" value={segundo_fac} onChange={e => setSegundoFac(e.target.value)} disabled={isLoaded} />
                        <br />
                        <Button type="submit" variant="contained">Crear</Button>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default MediaCard;
