import { useState } from 'react';


export default function useGetAutorizacionesHistorico() {

    const [dataHistorico, setDataHistorico] = useState([]);
    const token = localStorage.getItem('token');

    const getAutorizacionesHistorico = (sub) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_AUTORIZACIONES + `listarAutorizacionesHistorico?run=${sub}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setDataHistorico(result.listado);
            })
            .catch(error => console.log('error', error));
    }

    return {
        getAutorizacionesHistorico, dataHistorico
    }

}