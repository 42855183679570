import React, { useState, useRef, useEffect } from 'react'
import MenuSuperior from '../components/MenuSuperior';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert, InputLabel, Typography } from '@mui/material';
import { jwtDecode } from "jwt-decode";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import useEnviarCorreo from '../hooks/useEnviarCorreo';
import useEnviarSms from '../hooks/useEnviarSms';
import useGetAutorizacionesHistorico from '../hooks/useGetAutorizacionesHistorico';
import useGetInfoPersona from '../hooks/useGetInformacion';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import Swal from 'sweetalert2';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 4,
    outline: 'none'
};

export default function Autorizaciones() {

    const [isLoaded, setIsLoaded] = React.useState(false);
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const [data, setData] = React.useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [segundoFactor, setSegundoFactor] = useState(false);
    const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const { enviarNotificacion, result, loading } = useEnviarCorreo();
    const { enviarSMS, smsEnviado } = useEnviarSms();
    const { getAutorizacionesHistorico, dataHistorico } = useGetAutorizacionesHistorico();
    const { getInfo, userInitial, dataBeneficiario } = useGetInfoPersona();
    const [numbers, setNumbers] = useState(['', '', '', '', '', '']);
    const [timerExpired, setTimerExpired] = useState(false);
    const inputRefs = useRef(numbers.map(() => React.createRef()));
    const initialTime = localStorage.getItem('timer') ? parseInt(localStorage.getItem('timer'), 10) : 180;
    const [timer, setTimer] = useState(initialTime);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState(false);
    const sms = localStorage.getItem('smsEnviado');


    const formatMonto = (monto) => {
        return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(monto);
    };


    const getAutorizaciones = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_AUTORIZACIONES + `listarAutorizaciones?run=${decoded.sub}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.listado);
            })
            .catch(error => console.log('error', error));
    };

    const aprobarAutorizacion = (idSeleccionado, estado, segundo_fac) => {
        setIsLoaded(true);
        // if (segundo_fac === 1) {
        //     if (numbers.join('').length === 6) {
        //         autorizarSMS(idSeleccionado);
        //     } else {
        //         setError("Ingrese el código de 6 dígitos");
        //         setIsLoaded(false);
        //     }
        // } else {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_AUTORIZACIONES + `actualizarAutorizacion?id_transaccion=${idSeleccionado}&estado=${estado}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                getAutorizaciones();
                // if (estado === '1') {
                //     enviarNotificacion(estado);
                // } else if (estado === '2') {
                //     enviarNotificacion(estado);
                // }
                console.log(estado);
                if (estado === '1') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Autorización Aprobada',
                        text: 'La autorización ha sido aprobada',
                    });
                } else if (estado === '2') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Autorización Rechazada',
                        text: 'La autorización ha sido rechazada',
                    });
                }
                setIsLoaded(false);
                closeModal();
            })
            //     localStorage.removeItem('smsEnviado');
            //     getAutorizacionesHistorico(decoded.sub);
            //     setIsLoaded(false);
            //     closeModal();
            // })
            .catch(error => console.log('error', error));
    }
    const openModal = (transaction) => {
        setSelectedTransaction(transaction);
        setSegundoFactor(transaction.segundo_fac === '1');
        setTimer(initialTime);
        setIsModalOpen(true);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedTransaction(null);
        setIsModalOpen(false);
        setModalOpen(false);
        setNumbers(['', '', '', '', '', '']);
        setError(false);
        setTimer(initialTime);
        setTimerExpired(true);
    };

    useEffect(() => {
        getAutorizaciones(decoded.sub);
        getAutorizacionesHistorico(decoded.sub);
        getInfo(decoded.sub);
    }, []);

    const autorizarSMS = (idSeleccionado) => {
        if (!smsEnviado) {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL_AUTORIZACIONES + `validarSMS?id=${idSeleccionado}&codigo_sms=${numbers.join('')}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.mensaje_salida === "Todo ok") {
                        console.log(result);
                        aprobarAutorizacion(idSeleccionado, '1');
                    } else {
                        setError("Código incorrecto");
                    }
                })
                .catch(error => console.log('error', error));
        }
    };

    const dataOrdenada = dataHistorico?.sort((a, b) => {
        return new Date(b.fecha) - new Date(a.fecha);
    });

    useEffect(() => {
        const interval = setInterval(() => {
            getAutorizaciones(decoded.sub);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const handleTimerTick = () => {
        if (timer > 0) {
            setTimer(timer - 1);
            localStorage.setItem('timer', timer - 1);
        } else {
            setTimerExpired(true);
            localStorage.removeItem('timer');
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isModalOpen) {
                handleTimerTick();
            } else {
                localStorage.removeItem('timer');
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [timer, isModalOpen]);

    const handleInputChange = (index, value, event) => {
        if (/^\d*$/.test(value)) {
            const newNumbers = [...numbers];
            newNumbers[index] = value;
            setNumbers(newNumbers);

            if (value !== '' && index < numbers.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }

        if (event.key === 'Backspace' && value === '' && index > 0) {
            const newNumbers = [...numbers];
            newNumbers[index - 1] = '';
            setNumbers(newNumbers);
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <>
            <MenuSuperior initial={userInitial} />
            <Alert severity="info" sx={{ marginTop: '1%', marginLeft: '10%', width: '80%' }}>
                Bienvenido {dataBeneficiario?.nombres} {dataBeneficiario?.apellidoPaterno} {dataBeneficiario?.apellidoMaterno}, en esta sección podrás revisar el estado de tus autorizaciones.
            </Alert>
            <Modal
                open={modalOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        closeModal();
                    }
                }}
                disableEscapeKeyDown
                closeAfterTransition
                BackdropComponent={Backdrop}

            >
                <Fade in={modalOpen}>
                    <Box sx={style}>
                        {selectedTransaction && (
                            <div>
                                <Typography variant="h5" textAlign="center" sx={{ marginBottom: '1rem' }}>
                                    Autorizacion de Transacción
                                </Typography>
                                <span style={{ display: 'block', color: '#BFC9CA', marginBottom: '1rem' }}>
                                    {selectedTransaction?.fecha.split('T')[0]} {selectedTransaction?.fecha.split('T')[1].split('.')[0]}
                                </span>
                                Estimado {dataBeneficiario?.nombres} {dataBeneficiario?.apellidoPaterno} {dataBeneficiario?.apellidoMaterno}, se ha solicitado la autorización de la siguiente transacción:
                                <div style={{ marginTop: '1rem' }}>
                                    <span style={{ fontWeight: 'bold' }}>Nombre Prestador:</span> {selectedTransaction?.nombre_prestador}
                                </div>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Sucursal:</span> {selectedTransaction?.sucursal}
                                </div>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Aplicativo:</span> {selectedTransaction?.aplicativo}
                                </div>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Modulo:</span> {selectedTransaction?.modulo}
                                </div>

                                <Typography variant="h6" textAlign="center" sx={{ marginTop: '1rem' }}>
                                    {formatMonto(selectedTransaction?.monto)}
                                </Typography>

                                {/* {selectedTransaction.segundo_fac === 1 && (
                                    <div>
                                        <div>
                                            {timerExpired ? (
                                                <Typography variant="h6" color="error">
                                                    Tiempo agotado. Reinicia el proceso.
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Typography variant="h6">
                                                        Tiempo restante: {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
                                                    </Typography>
                                                    {sms === 'true' ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <Link onClick={() => enviarSMS(selectedTransaction.id_transaccion)} underline="none">
                                                            {'enviar sms'}
                                                        </Link>
                                                    )}
                                                    {error && (
                                                        <Alert severity="error">
                                                            {error}
                                                        </Alert>
                                                    )}
                                                </>

                                            )}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                                            {numbers.map((number, index) => (
                                                <TextField
                                                    key={index}
                                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                                    value={number}
                                                    onChange={(e) => handleInputChange(index, e.target.value, e)}
                                                    onKeyDown={(e) => handleInputChange(index, number, e)}
                                                    type="text"
                                                    inputProps={{ maxLength: 1 }}
                                                    sx={{ width: '40px', marginRight: '0.5rem' }}
                                                    variant="outlined"
                                                />
                                            ))}
                                        </div>
                                        <InputLabel sx={{ mb: 1 }} textAlign="center">Ingrese el código de 6 dígitos</InputLabel>
                                    </div>
                                )} */}
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}>
                                    <Button title='aprobar' variant="contained" color="primary" onClick={() => aprobarAutorizacion(selectedTransaction.id_transaccion, '1', selectedTransaction.segundo_fac)} sx={{ marginRight: '5%' }}>
                                        Aprobar
                                    </Button>
                                    <Button title='rechazar' variant="contained" color="error" onClick={() => aprobarAutorizacion(selectedTransaction.id_transaccion, '2')} sx={{ marginLeft: '5%' }}>
                                        Rechazar
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
            {data?.length === 0 ? (
                <>
                    <ErrorOutlineIcon sx={{ display: 'block', margin: 'auto', fontSize: 50, color: '#006fb3', marginTop: '10%' }} />
                    <Typography gutterBottom variant="h6" component="div" sx={{ marginLeft: '10%', width: '80%', textAlign: 'center' }}>
                        No tienes autorizaciones pendientes.
                    </Typography>
                    <Button variant="contained" sx={{ m: 1, marginLeft: '10%', width: '80%' }} onClick={() => window.location.href = '/cue/logout'}>Salir</Button>
                </>
            ) : (
                <>
                    <Typography gutterBottom variant="h6" component="div" sx={{ marginTop: '10%', marginLeft: '10%', width: '80%', textAlign: 'center' }} color={'#006fb3'}>
                        Autorizaciones Pendientes
                    </Typography>
                    {data?.map((row) => (
                        <>
                            <Card sx={{ maxWidth: 345, marginLeft: '10%', width: '80%', marginTop: '2%', borderRadius: '10px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)' }}>
                                <CardActionArea onClick={() => openModal(row)}>
                                    <CardContent>
                                        
                                        <span style={{ display: 'block', marginBottom: '1rem' }}>
                                            {row?.aplicativo}
                                        </span>
                                        <span style={{ display: 'block', marginBottom: '1rem' }}>
                                            {row?.modulo}
                                        </span>
                                        <span style={{ display: 'block', color: '#BFC9CA', marginBottom: '1rem' }}>
                                            {row?.fecha.split('T')[0]} {data[0]?.fecha.split('T')[1].split('.')[0]}
                                        </span>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </>

                    ))}
                </>
            )}
            {dataOrdenada && (
                <>
                    <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: '10%', marginLeft: '10%', width: '80%', textAlign: 'center' }} color={'#006fb3'}>
                        Listado de Historico de Autorizaciones
                    </Typography>
                    <Box
                        sx={{
                            width: '80%',
                            display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            marginLeft: '10%',
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Fecha</TableCell>
                                        <TableCell align="right">Estado</TableCell>
                                        <TableCell align="right">Folio</TableCell>
                                        <TableCell align="right">Módulo</TableCell>
                                        <TableCell align="right">Aplicativo</TableCell>
                                        <TableCell align="right">Run</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataHistorico?.map((row) => (
                                        <TableRow
                                            key={row.id_transaccion}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="right">
                                                {row.fecha.split('T')[0]}
                                                <span style={{ display: 'block', color: '#BFC9CA' }}>
                                                    {row.fecha.split('T')[1].split('.')[0]}
                                                </span>
                                            </TableCell>
                                            <TableCell align="right" style={{ color: row.estado === '1' ? '#2ECC71' : '#C0392B' }}>{row.estado === '1' ? 'Aprobada' : 'Rechazada'}</TableCell>
                                            <TableCell align="right">{row.folio}</TableCell>
                                            <TableCell align="right">{row.modulo}</TableCell>
                                            <TableCell align="right">{row.aplicativo}</TableCell>
                                            <TableCell align="right">{row.run}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {isLoaded ? (

                                <LinearProgress />

                            ) : (
                                <></>
                            )}
                        </TableContainer>
                    </Box>
                </>
            )}
        </>
    )
}
