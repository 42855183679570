import { useState } from 'react';
import { set } from 'react-hook-form';

export default function useEnviarSms() {

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const enviarSMS = (id_transaccion) => {
        setLoading(true);
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_AUTORIZACIONES + `enviarSMS?id=${id_transaccion}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false);
                localStorage.setItem('smsEnviado', true);
            })
            .catch(error => {
                console.log('error', error);
                setLoading(false);
            });
    }

    return {
        enviarSMS, result, loading
    }
}