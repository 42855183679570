import React, { useEffect } from "react";
import IconFonasa from "../assets/iconFonasa.jpg";
import CircularProgress from '@mui/material/CircularProgress';

export default function Logout() {

  useEffect(() => {
    localStorage.removeItem("token");
    if (localStorage.getItem("token") === null) {
      window.location.href = "/"
    }
  }, []);

  return (

    <div className="container text-center" style={{ marginTop: "10rem" }}>
      {/* <h1 className="display-5 fw-bold">
        Cerrando la Sesión
      </h1> */}
      <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
        <CircularProgress
          style={{ position: "absolute", width: "3rem", height: "3rem" }}
        />
        <img
          src={IconFonasa}
          alt="Fonasa"
          
          style={{ zIndex: "1" }}
          className="d-inline-block align-text-top"
        />
      </div>
    </div>
  );
}