import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Logo from "../assets/logoFonasa.png";
import MenuItem from '@mui/material/MenuItem';
import LoginIcon from '@mui/icons-material/Login';

function ResponsiveAppBar({ initial }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        window.location.href = process.env.REACT_APP_REDIRECT_LOGOUT;
    }

    return (
        <AppBar position="static" color="transparent" elevation={1}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <img src={Logo} alt="logo" style={{ width: "70%" }} />
                    </IconButton>
                    <Box sx={{ position: 'absolute', right: 0 }}>
                        <Tooltip>
                            <IconButton sx={{ p: 0 }} onClick={handleClick}>
                                <Avatar alt="User Avatar" sx={{ bgcolor: '#006fb3' }}>
                                    {initial}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleLogout}>Cerrar sesión<LoginIcon /></MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;