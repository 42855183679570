import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import Link from '@mui/material/Link';

const TimerCard = () => {
    const [numbers, setNumbers] = useState(['', '', '', '', '', '']);
    const [timerExpired, setTimerExpired] = useState(false);
    const inputRefs = useRef(numbers.map(() => React.createRef()));

    const initialTime = localStorage.getItem('timer') ? parseInt(localStorage.getItem('timer'), 10) : 180;
    const [timer, setTimer] = useState(initialTime);

    const handleTimerTick = () => {
        if (timer > 0) {
            setTimer(timer - 1);
            localStorage.setItem('timer', timer - 1);
        } else {
            setTimerExpired(true);
            localStorage.removeItem('timer');
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            handleTimerTick();
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    const handleSend = () => {
        if (numbers.every((num) => num !== '')) {
            console.log('Enviando:', numbers.join(''));
        } else {
            console.log('Por favor ingresa un número en cada cuadro.');
        }
    };

    const handleInputChange = (index, value, event) => {
        if (/^\d*$/.test(value)) {
            const newNumbers = [...numbers];
            newNumbers[index] = value;
            setNumbers(newNumbers);

            if (value !== '' && index < numbers.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }

        if (event.key === 'Backspace' && value === '' && index > 0) {
            const newNumbers = [...numbers];
            newNumbers[index - 1] = '';
            setNumbers(newNumbers);
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div>
            <div>
                {timerExpired ? (
                    <Typography variant="h6" color="error">
                        Tiempo agotado. Reinicia el proceso.
                    </Typography>
                ) : (
                    <>
                        <Typography variant="h6">
                            Tiempo restante: {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
                        </Typography>
                        <Link href="#" underline="none">
                            {'enviar sms'}
                        </Link>
                    </>

                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                {numbers.map((number, index) => (
                    <TextField
                        key={index}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        value={number}
                        onChange={(e) => handleInputChange(index, e.target.value, e)}
                        onKeyDown={(e) => handleInputChange(index, number, e)}
                        type="text"
                        style={{ width: '3rem', margin: '0 0.5rem' }}
                        inputProps={{ maxLength: 1, style: { textAlign: 'center', fontSize: '1.5rem' } }}
                        variant="outlined"
                    />
                ))}
            </div>
        </div>
    );
};

export default TimerCard;
