import React from "react";
import { Container } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Link } from "@mui/material";

function Footer() {
  const current = new Date();
  const year = current.getFullYear();


  return (
    <>
      <footer  style={{ backgroundColor: "#0a132d", position: "fixed", bottom: "0", width: "100%" }}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <img
                src="https://www.fonasa.cl/assets/fonasa2019/gob-footer.svg"
                alt="logo Ministerio de Salud"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body2" style={{color: "white", marginLeft: "10px"}}>
                © {year} Copyright FONASA, todos los derechos reservados
              </Typography>
              <Typography variant="body2"  style={{ marginLeft: "120px", color: "white" }}>
                Hecho con <span style={{ color: "red" }}>♥</span> por FONASA
              </Typography>
            </Grid>
          </Grid>
          <Link href="#" onClick={() => window.scrollTo(0, 0)}>
            <img
              src="https://www.fonasa.cl/assets/fonasa2019/up-e18a8814cae317654e2c61d648e0129c.svg"
              alt="button to top"
            />
          </Link>
        </Container>
      </footer>
    </>

  );
}

export default Footer;