import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import Copyright from "./Copyright";
function LayoutPublico() {
    const outlet = useOutlet();
    const token = localStorage.getItem('token');

    if (!token) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <main>{outlet}</main>
            <Copyright />
        </>
    );
}

export default LayoutPublico;