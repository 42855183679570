import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{position: "fixed", bottom: 0, left: 0, width: "100%"}}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.fonasa.cl/">
        Fondo Nacional de Salud
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}