import React from 'react';
import Swal from 'sweetalert2';


export default function useEnviarCorreo() {
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const enviarNotificacion = (estado) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const mensaje = estado === '1'
            ? `<p>Estimado Usuario,</p>
       <p>¡Saludos! Le informamos que su autorización ha sido aprobada. Para más información, visite <a href="https://autorizaciones.microservicio.cl/">https://autorizaciones.microservicio.cl/</a></p>`
            : `<p>Estimado Usuario,</p>
       <p>Le informamos que ha rechazadado su autorización. Para más información, visite <a href="https://autorizaciones.microservicio.cl/">https://autorizaciones.microservicio.cl/</a></p>`;

        var raw = JSON.stringify({
            "asunto": "Autorizacion Fonasa",
            "remitente": "NotificacionFonasa@fonasa.cl",
            "destinatarios": [
                "miguel.hernandez@fonasa.cl"
            ],
            "destinatariosConCopia": [],
            "destinatariosConCopiaOculta": [],
            "mensaje": mensaje,
            "adjuntos": []
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_RS_CORREO, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false);
                setResult(result);
                if (estado === '1') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Autorización Aprobada',
                        text: 'La autorización ha sido aprobada',
                    });
                } else if (estado === '2') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Autorización Rechazada',
                        text: 'La autorización ha sido rechazada',
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    return {
        enviarNotificacion, result, loading
    }
}