import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function App() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const codeUrl = searchParams.get("code");
    const state = searchParams.get("state");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (codeUrl && state) {
            getToken();
        }
    }, [codeUrl, state]);

    const getToken = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(process.env.REACT_APP_TOKEN_FONASA, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({ "state": state, "code": codeUrl ,"aplicativo":"autorizaciones"}),
                redirect: 'follow'
            });

            if (response.status === 200) {
                const data = await response.json();
                const token = data.jwt;
                console.log(data);
                localStorage.setItem("token", token);
                window.location.href = process.env.REACT_APP_REDIRECT;

            } else {
                setError("No se pudo obtener el token");
            }
        } catch (error) {
            setError('Error al obtener el token: ' + error.message);
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <div className="text-center text-primary" style={{ marginTop: "100px" }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center text-danger" style={{ marginTop: "100px" }}>
                <p>{error}</p>
            </div>
        );
    }

    return null;
}
